import React, { useEffect } from 'react';

const Login = () => {
    return (
        <div className="core">
            <div className='main'>
                <h1>Todavia no te puedes loguear, pero pronto si, es decir, hoy no, mañana.</h1>
            </div>
            <a className='btnTwitch heroes' href='/login?twitch=1'>Conectarse con Twitch</a>
        </div>
    )
}

export default Login;