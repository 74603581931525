import React from 'react';
import { Link } from 'react-router-dom';
import homeImage from './images/home.png';
import searchImage from './images/search.png';
import redesImage from './images/Redes100.png';
import infoImage from './images/info.png';
import userImage from './images/user.png';

const MenuMain = () => (
    <div id="menu-main" className="noselect">
        <Link id="son" to="/"><img src={homeImage} alt="Inicio"/><span className="responsive">Inicio</span></Link>
        <Link id="son" to="/user"><img src={searchImage} alt="Explorar"/><span className="responsive">Explorar</span></Link>
        <div id='son' className='infoExtender'><img src={infoImage} alt="info"/><span className="responsive">Info</span></div>
        <Link id="son" to="/redes"><img src={redesImage} alt="Redes"/><span className="responsive">Redes</span></Link>
        <Link id="son" to="/myaccount"><img src={userImage} alt="Usuario"/><span className="responsive">Usuario</span></Link>
    </div>
)

export default MenuMain;